import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { API_BASE_URL_JSON } from 'src/app/data-wp.service';
import { emailData } from "src/app/shared/emailData";
import { SocioData } from "src/app/shared/socio-data.interface";

@Component({
  selector: "app-reclamo-de-correo-inicial",
  templateUrl: "./reclamo-de-correo-inicial.component.html",
  styleUrls: ["../../socios-gestion-online.component.css"],
})
export class ReclamoDeCorreoInicialComponent implements OnInit {
  form: FormGroup;

  @Output() closeModal = new EventEmitter();

  constructor(public formBuilder: FormBuilder, private http: HttpClient) {}

  get emailReclamo() {
    return this.contactForm.get("emailReclamo");
  }

  contactForm = this.formBuilder.group({
    emailReclamo: [
      "",
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"),
      ],
    ],
  });

  datosSocio: SocioData;

  ngOnInit() {
    this.datosSocio = JSON.parse(sessionStorage.getItem("data"));
  }
  submitForm() {
    let formData = new FormData();
    let form = this.contactForm.value;
    formData.append("numero_afiliado", this.datosSocio.socio_numero);
    formData.append("nombre_afiliado", this.datosSocio.socio_nombre);
    formData.append("apellido_afiliado", this.datosSocio.socio_apellido);
    formData.append("email_afiliado", this.datosSocio.socio_email);
    formData.append("dominio_afiliado", this.datosSocio.socio_dominio);
    formData.append("email_reclamo", form.emailReclamo);

    this.datosSocio.socio_dominio.length > 0
      ? formData.append(
          "email_destino",
          emailData[this.datosSocio.socio_dominio]
        )
      : formData.append("email_destino", "cas@vittal.com.ar");

    this.http
      .post<any>(
        API_BASE_URL_JSON + "/contact-form-7/v1/contact-forms/3714/feedback",
        formData
      )
      .subscribe((res) => {
        if ((res.status = "mail_sent")) {
          this.closeModal.emit();
          alert(res.message);
        } else {
          alert(res.message);
        }
      });
  }
}
