import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { validarQueSeanIguales } from "../../../contacto/validators/pass-validator";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SessionStorageService } from "src/app/services/session-storage.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { GenericResponse } from "src/app/generic-response.interface";
import { API_BASE_URL_V } from "src/app/data-wp.service";
@Component({
  selector: "app-registro-empresa",
  templateUrl: "./registro-empresa.component.html",
  styleUrls: ["./registro-empresa.component.css"],
})
export class RegistroEmpresaComponent implements OnInit {
  isHome;
  planValue: Observable<any>;
  isConvenio;
  @ViewChild("modalEnvioOk", { static: false }) modalEnvioOk: ElementRef;
  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;
  constructor(
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    private ssService: SessionStorageService,
    private http: HttpClient,
    private spinner: NgxUiLoaderService
  ) {}

  get selectPlan() {
    return this.contactForm.get("selectPlan");
  }
  get codigoEmpresaReg() {
    return this.contactForm.get("codigoEmpresaReg");
  }
  get facturaEmpresaReg() {
    return this.contactForm.get("facturaEmpresaReg");
  }
  get cuitEmpresaReg() {
    return this.contactForm.get("cuitEmpresaReg");
  }
  get nombreEmpresaReg() {
    return this.contactForm.get("nombreEmpresaReg");
  }
  get emailEmpresaReg() {
    return this.contactForm.get("emailEmpresaReg");
  }
  get telefonoEmpresaReg() {
    return this.contactForm.get("telefonoEmpresaReg");
  }
  get passEmpresaReg() {
    return this.contactForm.get("passEmpresaReg");
  }
  get pass2EmpresaReg() {
    return this.contactForm.get("pass2EmpresaReg");
  }

  contactForm = this.formBuilder.group({
    selectPlan: ["", [Validators.required]],
    codigoEmpresaReg: ["", [Validators.required]],
    facturaEmpresaReg: ["", [Validators.required]],
    cuitEmpresaReg: [
      "",
      [Validators.required, Validators.pattern("^[0-9]{11}$")],
    ],
    nombreEmpresaReg: ["", [Validators.required]],
    emailEmpresaReg: [
      "",
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"),
      ],
    ],
    telefonoEmpresaReg: [
      "",
      [Validators.required, Validators.pattern("[0-9]+")],
    ],
    passEmpresaReg: ["", [Validators.required]],
    pass2EmpresaReg: ["", [Validators.required]],
  });
  screenSize;
  modalMessage: string;
  /*Funcion cuando se hace submit*/
  submitForm() {
    this.spinner.start();
    let formData: any = new FormData();
    formData.append("empresa_cuit", this.contactForm.value.cuitEmpresaReg);
    formData.append("empresa_nombre", this.contactForm.value.nombreEmpresaReg);
    formData.append("empresa_password", this.contactForm.value.passEmpresaReg);
    formData.append("empresa_email", this.contactForm.value.emailEmpresaReg);
    formData.append(
      "empresa_telefono",
      this.contactForm.value.telefonoEmpresaReg
    );
    if (this.isConvenio == 1) {
      formData.append(
        "empresa_codigo",
        this.contactForm.value.codigoEmpresaReg
      );
      this.http
        .post<GenericResponse>(
          API_BASE_URL_V + "/area-protegida/signup",
          formData
        )
        .subscribe((e) => {
          this.spinner.stop();
          if (e.success) {
            this.openModalSendOk(this.modalEnvioOk);
          } else {
            this.modalMessage = e.message.toLowerCase();
            this.openModalSendOk(this.modalRespuesta);
          }
        });
    } else {
      formData.append(
        "empresa_factura",
        this.contactForm.value.facturaEmpresaReg
      );
      this.http
        .post<GenericResponse>(API_BASE_URL_V + "/convenio/signup", formData)
        .subscribe((e) => {
          this.spinner.stop();
          if (e.success) {
            this.openModalSendOk(this.modalEnvioOk);
          } else {
            this.modalMessage = e.message.toLowerCase();
            this.openModalSendOk(this.modalRespuesta);
          }
        });
    }
    //alert('Registro Empresa OK')
  }

  /*Funcion para abrir modal problemas de acceso*/
  openModalSendOk(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-problemas",
      backdrop: "static",
    });
  }

  ngOnInit() {
    this.screenSize = window.innerWidth;
    this.ssService.isHome().subscribe((e) => {
      this.isHome = e;
    });
  }

  volverGestion() {
    this.modalService.dismissAll();
    document.getElementById("volverGestion").classList.remove("show");
    document.getElementById("loginSocios").classList.remove("show");
    document.getElementById("loginEmpresa").classList.remove("show");
    document.getElementById("contentRegistro").classList.remove("show");
    document.getElementById("registroSocios").classList.remove("show");
    document.getElementById("registroEmpresa").classList.remove("show");
    document.getElementById("loginFront").classList.add("show");
    document.getElementById("contentLogin").classList.add("show");
    if (this.screenSize < 760) {
      document.getElementById("titulosMobile").classList.add("show");
    }
    this.ssService.setHome();
  }

  tipoPlan(event) {
    this.isConvenio = event;
    if (event == 0) {
      this.codigoEmpresaReg.clearValidators();
      this.codigoEmpresaReg.updateValueAndValidity();
      this.facturaEmpresaReg.setValidators([Validators.required]);
      this.facturaEmpresaReg.updateValueAndValidity();
    }
    if (event == 1) {
      this.facturaEmpresaReg.clearValidators();
      this.facturaEmpresaReg.updateValueAndValidity();
      this.codigoEmpresaReg.setValidators([Validators.required]);
      this.codigoEmpresaReg.updateValueAndValidity();
    }
  }
}
