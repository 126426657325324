import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { phoneNumberValidator } from "../../../contacto/validators/phone-validator";
import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import {
  DataWpServicePrecios,
  API_BASE_URL_JSON,
} from "../../../data-wp.service";
import { PostI } from "../../../post.interface";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-form-cotizar",
  templateUrl: "./form-cotizar.component.html",
  styleUrls: ["./form-cotizar.component.css"],
  providers: [DataWpServicePrecios],
})
export class FormCotizarComponent implements OnInit {
  @Input() codePrice; /*INPUT CON CODIGO DE PRECIO*/
  @Input() codeForm: number; /*INPUT CON CODIGO DE FORMULARIO PARA ENVIAR*/
  @Input() tituloForm: string; /*INPUT CON CODIGO DE FORMULARIO PARA ENVIAR*/
  @Input() isMobile: boolean;
  @Input() isBitrix: boolean;
  form: FormGroup; /* FORMUALRIO VALIDADORES, ETC */
  //posts: PostI[]; /*Interfaz para levantar data*/
  posts = [];

  @ViewChild("t", { static: false }) selectCantidad: ElementRef;

  constructor(
    public formBuilder: FormBuilder,
    private http: HttpClient,
    private postsService: DataWpServicePrecios,
    private router: Router
  ) { }

  get nombre() {
    return this.contactForm.get("nombre");
  }
  get apellido() {
    return this.contactForm.get("apellido");
  }
  get email() {
    return this.contactForm.get("email");
  }
  get telefono() {
    return this.contactForm.get("telefono");
  }
  get localidad() {
    return this.contactForm.get("localidad");
  }
  get edad1() {
    return this.contactForm.get("edad1");
  }
  get edad2() {
    return this.contactForm.get("edad2");
  }
  get edad3() {
    return this.contactForm.get("edad3");
  }
  get edad4() {
    return this.contactForm.get("edad4");
  }
  get edad5() {
    return this.contactForm.get("edad5");
  }
  get edad6() {
    return this.contactForm.get("edad6");
  }
  get edad7() {
    return this.contactForm.get("edad7");
  }
  get edad8() {
    return this.contactForm.get("edad8");
  }
  get edad9() {
    return this.contactForm.get("edad9");
  }
  get edad10() {
    return this.contactForm.get("edad10");
  }

  nombrePlan;
  icono;
  slug_plan;
  textoPersonas: string;
  contactForm = this.formBuilder.group({
    nombre: ["", [Validators.required]],
    apellido: ["", [Validators.required]],
    email: [
      "",
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"),
      ],
    ],
    telefono: ["", [Validators.required, phoneNumberValidator]],
    localidad: ["", [Validators.required]],
    edad1: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad2: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad3: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad4: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad5: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad6: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad7: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad8: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad9: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad10: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
  });

  spanTop() {
    document.getElementById("areaSpan").classList.add("top");
    document.getElementById("contentSelect").classList.add("ok");
  }
  spanTopEdades() {
    document.getElementById("edadesSpan").classList.add("top");
  }

  valor = 0;
  selectHijos(value) {
    this.valor = value;
    if (value == 1) {
      document.getElementById("contentField1").classList.add("show");
      document.getElementById("contentField2").classList.remove("show");
      document.getElementById("contentField3").classList.remove("show");
      document.getElementById("contentField4").classList.remove("show");
      document.getElementById("contentField5").classList.remove("show");
      document.getElementById("contentField6").classList.remove("show");
      document.getElementById("contentField7").classList.remove("show");
      document.getElementById("contentField8").classList.remove("show");
      document.getElementById("contentField9").classList.remove("show");
      document.getElementById("contentField10").classList.remove("show");
    }
    if (value == 2) {
      document.getElementById("contentField1").classList.add("show");
      document.getElementById("contentField2").classList.add("show");
      document.getElementById("contentField3").classList.remove("show");
      document.getElementById("contentField4").classList.remove("show");
      document.getElementById("contentField5").classList.remove("show");
      document.getElementById("contentField6").classList.remove("show");
      document.getElementById("contentField7").classList.remove("show");
      document.getElementById("contentField8").classList.remove("show");
      document.getElementById("contentField9").classList.remove("show");
      document.getElementById("contentField10").classList.remove("show");
    }
    if (value == 3) {
      document.getElementById("contentField1").classList.add("show");
      document.getElementById("contentField2").classList.add("show");
      document.getElementById("contentField3").classList.add("show");
      document.getElementById("contentField4").classList.remove("show");
      document.getElementById("contentField5").classList.remove("show");
      document.getElementById("contentField6").classList.remove("show");
      document.getElementById("contentField7").classList.remove("show");
      document.getElementById("contentField8").classList.remove("show");
      document.getElementById("contentField9").classList.remove("show");
      document.getElementById("contentField10").classList.remove("show");
    }
    if (value == 4) {
      document.getElementById("contentField1").classList.add("show");
      document.getElementById("contentField2").classList.add("show");
      document.getElementById("contentField3").classList.add("show");
      document.getElementById("contentField4").classList.add("show");
      document.getElementById("contentField5").classList.remove("show");
      document.getElementById("contentField6").classList.remove("show");
      document.getElementById("contentField7").classList.remove("show");
      document.getElementById("contentField8").classList.remove("show");
      document.getElementById("contentField9").classList.remove("show");
      document.getElementById("contentField10").classList.remove("show");
    }
    if (value == 5) {
      document.getElementById("contentField1").classList.add("show");
      document.getElementById("contentField2").classList.add("show");
      document.getElementById("contentField3").classList.add("show");
      document.getElementById("contentField4").classList.add("show");
      document.getElementById("contentField5").classList.add("show");
      document.getElementById("contentField6").classList.remove("show");
      document.getElementById("contentField7").classList.remove("show");
      document.getElementById("contentField8").classList.remove("show");
      document.getElementById("contentField9").classList.remove("show");
      document.getElementById("contentField10").classList.remove("show");
    }
    if (value == 6) {
      document.getElementById("contentField1").classList.add("show");
      document.getElementById("contentField2").classList.add("show");
      document.getElementById("contentField3").classList.add("show");
      document.getElementById("contentField4").classList.add("show");
      document.getElementById("contentField5").classList.add("show");
      document.getElementById("contentField6").classList.add("show");
      document.getElementById("contentField7").classList.remove("show");
      document.getElementById("contentField8").classList.remove("show");
      document.getElementById("contentField9").classList.remove("show");
      document.getElementById("contentField10").classList.remove("show");
    }
    if (value == 7) {
      document.getElementById("contentField1").classList.add("show");
      document.getElementById("contentField2").classList.add("show");
      document.getElementById("contentField3").classList.add("show");
      document.getElementById("contentField4").classList.add("show");
      document.getElementById("contentField5").classList.add("show");
      document.getElementById("contentField6").classList.add("show");
      document.getElementById("contentField7").classList.add("show");
      document.getElementById("contentField8").classList.remove("show");
      document.getElementById("contentField9").classList.remove("show");
      document.getElementById("contentField10").classList.remove("show");
    }
    if (value == 8) {
      document.getElementById("contentField1").classList.add("show");
      document.getElementById("contentField2").classList.add("show");
      document.getElementById("contentField3").classList.add("show");
      document.getElementById("contentField4").classList.add("show");
      document.getElementById("contentField5").classList.add("show");
      document.getElementById("contentField6").classList.add("show");
      document.getElementById("contentField7").classList.add("show");
      document.getElementById("contentField8").classList.add("show");
      document.getElementById("contentField9").classList.remove("show");
      document.getElementById("contentField10").classList.remove("show");
    }
    if (value == 9) {
      document.getElementById("contentField1").classList.add("show");
      document.getElementById("contentField2").classList.add("show");
      document.getElementById("contentField3").classList.add("show");
      document.getElementById("contentField4").classList.add("show");
      document.getElementById("contentField5").classList.add("show");
      document.getElementById("contentField6").classList.add("show");
      document.getElementById("contentField7").classList.add("show");
      document.getElementById("contentField8").classList.add("show");
      document.getElementById("contentField9").classList.add("show");
      document.getElementById("contentField10").classList.remove("show");
    }
    if (value == 10) {
      document.getElementById("contentField1").classList.add("show");
      document.getElementById("contentField2").classList.add("show");
      document.getElementById("contentField3").classList.add("show");
      document.getElementById("contentField4").classList.add("show");
      document.getElementById("contentField5").classList.add("show");
      document.getElementById("contentField6").classList.add("show");
      document.getElementById("contentField7").classList.add("show");
      document.getElementById("contentField8").classList.add("show");
      document.getElementById("contentField9").classList.add("show");
      document.getElementById("contentField10").classList.add("show");
    }
  }

  ngOnInit() {
    this.postsService.getPosts().subscribe(
      (posts) => {
        this.posts = [...posts];
      },
      (err: HttpErrorResponse) =>
        err.error instanceof Error
          ? console.log("An error occurred:", err.error.message)
          : console.log(
            `Backend returned code ${err.status}, body was: ${err.error}`
          )
    );
    //console.log(this.codeForm);
  }

  selectPost(slug) {
    this.router.navigate([slug]);
  }

  precioCotizacion = 0;

  sendForm(
    nombre,
    price_1p,
    price_2p,
    price_3p,
    price_4p,
    price_p_adicional,
    price_copago,
    precio_adicional_65a69,
    precio_adicional_70a74,
    precio_adicional_75a79,
    precio_adicional_80mas
  ) {
    /*SETEOS*/
    var menor = 0;
    var adult = 0;
    var adult64a69 = 0;
    var adult70a74 = 0;
    var adult75a79 = 0;
    var adult80mas = 0;

    /*PRECIOS*/
    price_1p = parseFloat(price_1p);
    price_2p = parseFloat(price_2p);
    price_3p = parseFloat(price_3p);
    price_4p = parseFloat(price_4p);
    price_p_adicional = parseFloat(price_p_adicional);
    price_copago = parseFloat(price_copago);
    precio_adicional_65a69 = parseFloat(precio_adicional_65a69);
    precio_adicional_70a74 = parseFloat(precio_adicional_70a74);
    precio_adicional_75a79 = parseFloat(precio_adicional_75a79);
    precio_adicional_80mas = parseFloat(precio_adicional_80mas);

    /*EDADES*/
    var calcEdad1 = parseFloat(this.contactForm.get("edad1").value);
    var calcEdad2 = parseFloat(this.contactForm.get("edad2").value);
    var calcEdad3 = parseFloat(this.contactForm.get("edad3").value);
    var calcEdad4 = parseFloat(this.contactForm.get("edad4").value);
    var calcEdad5 = parseFloat(this.contactForm.get("edad5").value);
    var calcEdad6 = parseFloat(this.contactForm.get("edad6").value);
    var calcEdad7 = parseFloat(this.contactForm.get("edad7").value);
    var calcEdad8 = parseFloat(this.contactForm.get("edad8").value);
    var calcEdad9 = parseFloat(this.contactForm.get("edad9").value);
    var calcEdad10 = parseFloat(this.contactForm.get("edad10").value);

    /* CALCULA VALORES A MOTRAR EN PLACA DE RESULTADOS */
    if (this.valor) {
      for (var i = 0; i < this.valor; i++) {
        var val_item = i + 1;
        var people_value = 0;
        if (val_item == 1) {
          people_value = calcEdad1;
        }
        if (val_item == 2) {
          people_value = calcEdad2;
        }
        if (val_item == 3) {
          people_value = calcEdad3;
        }
        if (val_item == 4) {
          people_value = calcEdad4;
        }
        if (val_item == 5) {
          people_value = calcEdad5;
        }
        if (val_item == 6) {
          people_value = calcEdad6;
        }
        if (val_item == 7) {
          people_value = calcEdad7;
        }
        if (val_item == 8) {
          people_value = calcEdad8;
        }
        if (val_item == 9) {
          people_value = calcEdad9;
        }
        if (val_item == 10) {
          people_value = calcEdad10;
        }
        var edad_avanzada = false;

        if (people_value > 11) {
          if (people_value > 64 && people_value < 70) {
            adult64a69++;
            edad_avanzada = true;
          }

          if (people_value > 69 && people_value < 75) {
            adult70a74++;
            edad_avanzada = true;
          }

          if (people_value > 74 && people_value < 80) {
            adult75a79++;
            edad_avanzada = true;
          }

          if (people_value > 79) {
            adult80mas++;
            edad_avanzada = true;
          }

          if (edad_avanzada == false) {
            adult++;
          }
        } else {
          menor++;
        }
      }

      //Suma todos los adultos
      adult = adult + adult64a69 + adult70a74 + adult75a79 + adult80mas + menor;
      if (adult == 1) {
        var price = price_1p;
      }
      if (adult == 2) {
        var price = price_2p;
      }
      if (adult == 3) {
        var price = price_3p;
      }
      if (adult == 4) {
        var price = price_4p;
      }
      if (adult == 5) {
        var price = price_4p + price_p_adicional;
      }
      if (adult == 6) {
        var price = price_4p + price_p_adicional * 2;
      }
      if (adult == 7) {
        var price = price_4p + price_p_adicional * 3;
      }
      if (adult == 8) {
        var price = price_4p + price_p_adicional * 4;
      }
      if (adult == 9) {
        var price = price_4p + price_p_adicional * 5;
      }
      if (adult == 10) {
        var price = price_4p + price_p_adicional * 6;
      }
      /*ADICIONALES MAYORES*/
      if (adult64a69 > 0) {
        var price = price + precio_adicional_65a69 * adult64a69;
      }
      if (adult70a74 > 0) {
        var price = price + precio_adicional_70a74 * adult70a74;
      }
      if (adult75a79 > 0) {
        var price = price + precio_adicional_75a79 * adult75a79;
      }
      if (adult80mas > 0) {
        var price = price + precio_adicional_80mas * adult80mas;
      }
    }

    this.posts.forEach((post) => {
      if (post.id == this.codePrice) {
        this.icono = post.acf.icono_blanco;
        this.slug_plan = post.acf.slug_plan;
        this.nombrePlan = post.nombre;
      }
    });

    /*MOSTRAR RESULTADOS EN PLACA*/

    // document.getElementById("nombrePlan").innerHTML = 'Plan ' + nombre;
    this.nombrePlan = nombre;
    this.valor > 1
      ? (this.textoPersonas = "personas")
      : (this.textoPersonas = "persona");
    /* AGREGA CLASE PARA MOSTRAR PLACA */
    document.getElementById("contentAll").classList.add("enviado");
    this.isMobile
      ? document.getElementById("boxCalculador").classList.remove("none")
      : "";
    document.getElementById("resultadoCotizacion").innerHTML = price;

    var formData: any = new FormData();
    formData.append("nombre", this.contactForm.get("nombre").value);
    formData.append("apellido", this.contactForm.get("apellido").value);
    formData.append("email", this.contactForm.get("email").value);
    formData.append("telefono", this.contactForm.get("telefono").value);
    formData.append("localidad", this.contactForm.get("localidad").value);
    formData.append("edad1", this.contactForm.get("edad1").value);
    formData.append("edad2", this.contactForm.get("edad2").value);
    formData.append("edad3", this.contactForm.get("edad3").value);
    formData.append("edad4", this.contactForm.get("edad4").value);
    formData.append("edad5", this.contactForm.get("edad5").value);
    formData.append("edad6", this.contactForm.get("edad6").value);
    formData.append("edad7", this.contactForm.get("edad7").value);
    formData.append("edad8", this.contactForm.get("edad8").value);
    formData.append("edad9", this.contactForm.get("edad9").value);
    formData.append("edad10", this.contactForm.get("edad10").value);
    formData.append("precio", price);

    if (this.isBitrix) {
      this.http
        .post<any>(
          `https://vittal.bitrix24.es/rest/581/frwu3wzs833exxye/crm.deal.add?FIELDS[UF_CRM_1634135217095]=${this.contactForm.get("nombre").value
          } ${this.contactForm.get("apellido").value
          }&FIELDS[CATEGORY_ID]=3&FIELDS[STAGE_ID]=C3:NEW&FIELDS[UF_CRM_1631106488]=${location.href
          }&FIELDS[STATUS_ID]=NEW&FIELDS[UF_CRM_1634135302317]=${this.contactForm.get("telefono").value
          }&FIELDS[UF_CRM_1634135324735]=${this.contactForm.get("email").value
          }&FIELDS[UF_CRM_1629725987]=${this.valor.toString()}&FIELDS[ASSIGNED_BY_ID]=29`,
          ""
        )
        .subscribe();
    } else {
      this.http
        .post(
          API_BASE_URL_JSON +
          "/contact-form-7/v1/contact-forms/" +
          this.codeForm +
          "/feedback",
          formData
        )
        .subscribe();
    }
  }

  submitForm(formCode) { }
  contratar() {
    // window.open("https://www.vittal.com.ar/gestion/ecommerce/plan-one", "_blank");
    window.open("https://www.vittal.com.ar/gestion/ecommerce/plan-one-ambulancias", "_blank");
    //window.open('https://www.vittal.com.ar/ecommerce/' + this.slug_plan, '_blank') asi deberia quedar
  }
  otraConsulta() {
    document.getElementById("contentAll").classList.remove("enviado");
    //location.reload();
    //return false;
  }

  resetForm() {
    this.contactForm.reset();
    this.selectCantidad.nativeElement.value = 0;
    document.getElementById("contentAll").classList.remove("enviado");
    this.isMobile
      ? document.getElementById("boxCalculador").classList.add("none")
      : "";
  }
}
