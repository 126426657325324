import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { API_BASE_URL_V } from "src/app/data-wp.service";
import { AuthService } from "src/app/services/auth.service";
import { EmpresaData } from "src/app/shared/empresa-data.interface";

@Component({
  selector: "app-empresa-facturacion",
  templateUrl: "./empresa-facturacion.component.html",
  styleUrls: ["./empresa-facturacion.component.css"],
})
export class EmpresaFacturacionComponent implements OnInit {
  constructor(
    private authSrv: AuthService,
    private http: HttpClient,
    private modalService: NgbModal,
    private spinner: NgxUiLoaderService
  ) {}

  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;
  datosEmpresa: EmpresaData;
  modalMessage: string;
  facturas;
  ngOnInit() {
    this.datosEmpresa = JSON.parse(sessionStorage.getItem("data"));
    this.spinner.start();
    this.http
      .get<any>(API_BASE_URL_V + "/empresa/facturas", {
        params: {
          empresa_codigo: this.datosEmpresa.empresa_codigo,
          empresa_dominio: this.datosEmpresa.empresa_dominio,
        },
      })
      .subscribe((res) => {
        if (res.success) {
          this.spinner.stop();
          if (res.data.facturas) {
            this.facturas = res.data.facturas;
            console.log(this.facturas);
          }
        } else {
          this.spinner.stop();
          this.modalMessage = res.message;
          this.openModal(this.modalRespuesta);
        }
      });
  }

  logout() {
    this.authSrv.logout();
  }
  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }

  getMonth(fecha) {
    let formatofecha = fecha.split("/");
    let fechafinal =
      formatofecha[1] + "/" + formatofecha[0] + "/" + formatofecha[2];
    return new Intl.DateTimeFormat("es-ES", { month: "long" }).format(
      new Date(fechafinal)
    );
  }
  getYear(fecha) {
    let formatofecha = fecha.split("/");

    return formatofecha[2];
  }
}
