import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { API_BASE_URL_JSON } from "src/app/data-wp.service";
import { emailData } from "src/app/shared/emailData";
import { EmpresaData } from "src/app/shared/empresa-data.interface";

@Component({
  selector: "app-extension-area-protegida",
  templateUrl: "./extension-area-protegida.component.html",
  styleUrls: ["../../empresa-gestion-online.component.css"],
})
export class ExtensionAreaProtegidaComponent implements OnInit {
  constructor(
    public formBuilder: FormBuilder,
    private http: HttpClient,
    private modalService: NgbModal,
    private datepipe: DatePipe
  ) {}

  form: FormGroup;
  datosEmpresa: EmpresaData;
  testDatos: EmpresaData;
  modalMessage: string;
  hoy;
  fechaHoy;
  @Output() closeModal = new EventEmitter();
  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;

  contactForm = this.formBuilder.group({
    direccion: ["", [Validators.required]],
    fecha: ["", [Validators.required]],
    horario: ["", [Validators.required]],
    localidad: ["", [Validators.required]],
    cantidadPersonas: ["", [Validators.required, Validators.min(1)]],
    comentario: [""],
  });

  get direccion() {
    return this.contactForm.get("direccion");
  }
  get fecha() {
    return this.contactForm.get("fecha");
  }
  get cantidadPersonas() {
    return this.contactForm.get("cantidadPersonas");
  }
  get horario() {
    return this.contactForm.get("horario");
  }
  get localidad() {
    return this.contactForm.get("localidad");
  }
  get comentario() {
    return this.contactForm.get("comentario");
  }

  ngOnInit() {
    this.datosEmpresa = JSON.parse(sessionStorage.getItem("data"));
    //////////////////////////////////////
    /// For Test
    /////////////////////////////////////
    this.testDatos = {
      empresa_cuit: "",
      empresa_codigo: "",
      empresa_razon_social: "",
      empresa_dominio: "",
      empresa_email: "",
      empresa_factura: "",
      empresa_nombre: "",
      empresa_plan_tipo: "",
      empresa_telefono: "",
    };
    this.fechaHoy = Date.now();
    this.hoy = this.datepipe.transform(this.fechaHoy, "yyyy-MM-dd");
  }

  submitForm() {
    //this.datosEmpresa = Object.assign({},this.testDatos);

    let formData = new FormData();
    let form = this.contactForm.value;
    formData.append("empresa_codigo", this.datosEmpresa.empresa_codigo);
    formData.append("empresa_cuit", this.datosEmpresa.empresa_cuit);
    this.datosEmpresa.empresa_nombre.length > 0
      ? formData.append(
          "empresa_razon_social",
          this.datosEmpresa.empresa_nombre
        )
      : formData.append("empresa_razon_social", "NO posee razón social");
    formData.append("empresa_dominio", this.datosEmpresa.empresa_dominio);
    formData.append("empresa_email", this.datosEmpresa.empresa_email);
    formData.append("direccion", form.direccion);
    formData.append("localidad", form.localidad);
    formData.append("fecha", form.fecha);
    formData.append("horario", form.horario);
    formData.append("cantidadPersonas", form.cantidadPersonas);
    form.comentario.length > 0
      ? formData.append("comentario", form.comentario)
      : formData.append("comentario", " ");

    this.datosEmpresa.empresa_dominio.length > 0
      ? formData.append(
          "email_destino",
          emailData[this.datosEmpresa.empresa_dominio]
        )
      : formData.append("email_destino", "cas@vittal.com.ar");

    this.http
      .post<any>(
        API_BASE_URL_JSON + "/contact-form-7/v1/contact-forms/3821/feedback",
        formData
      )
      .subscribe((res) => {
        console.log(res);
        if ((res.status = "mail_sent")) {
          this.closeModal.emit();
          this.modalMessage = res.message;
          this.openModal(this.modalRespuesta);
        } else {
          this.modalMessage = res.message;
          this.openModal(this.modalRespuesta);
        }
      });
  }
  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }
}
