import { Component, OnInit, Input, AfterViewInit, Output, ViewChild, ElementRef, EventEmitter, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_JSON } from '../../../../data-wp.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: "app-calculador",
  templateUrl: "./calculador.component.html",
  styleUrls: ["./calculador.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class CalculadorComponent implements OnInit {
  @Input() primer; /* INPUT DEL SLUG DEL PLAN*/

  @Input() formCode; /* INPUT DEL ID DEL FORMULARIO A USAR*/
  @Input() idPrecio; /* INPUT DEL ID DEL NOMBRE DEL PLAN*/
  @Input() nombre; /* INPUT DEL ID DEL NOMBRE DEL PLAN*/
  @Input() planName; /* INPUT DEL NOMBRE DEL PLAN*/
  @Input() slug_plan; /* INPUT DEL SLUG DEL PLAN*/
  @Input() icono; /* INPUT DEL ICONO BLANCO DEL PLAN*/
  @Input() price_1p; /* INPUT DEL PRECIO DEL PLAN*/
  @Input() price_2p; /* INPUT DEL PRECIO DEL PLAN*/
  @Input() price_3p; /* INPUT DEL PRECIO DEL PLAN*/
  @Input() price_4p; /* INPUT DEL PRECIO DEL PLAN*/
  @Input() price_p_adicional; /* INPUT DEL PRECIO DEL PLAN*/
  @Input() price_copago; /* INPUT DEL PRECIO DEL PLAN*/
  @Input() precio_adicional_65a69; /* INPUT DEL PRECIO DEL PLAN*/
  @Input() precio_adicional_70a74; /* INPUT DEL PRECIO DEL PLAN*/
  @Input() precio_adicional_75a79; /* INPUT DEL PRECIO DEL PLAN*/
  @Input() precio_adicional_80mas; /* INPUT DEL PRECIO DEL PLAN*/

  @Input() valor; /* CANTIDAD DE INTEGRANTES */
  @Input() nombrePersona; /* INPUT DEL NOMBRE DE LA PERSONA*/
  @Input() apellidoPersona; /* INPUT DEL APELLIDO DE LA PERSONA*/
  @Input() emailPersona; /* INPUT DEL EMAIL DE LA PERSONA*/
  @Input() telefonoPersona; /* INPUT DEL TELEFONO DE LA PERSONA*/
  @Input() localidadPersona; /* INPUT DE LA LOCALIDAD DE LA PERSONA*/
  @Input() datoFormEdad1; /*INPUT EDAD DE INTEGRRANTE*/
  @Input() datoFormEdad2; /*INPUT EDAD DE INTEGRRANTE*/
  @Input() datoFormEdad3; /*INPUT EDAD DE INTEGRRANTE*/
  @Input() datoFormEdad4; /*INPUT EDAD DE INTEGRRANTE*/
  @Input() datoFormEdad5; /*INPUT EDAD DE INTEGRRANTE*/
  @Input() datoFormEdad6; /*INPUT EDAD DE INTEGRRANTE*/
  @Input() datoFormEdad7; /*INPUT EDAD DE INTEGRRANTE*/
  @Input() datoFormEdad8; /*INPUT EDAD DE INTEGRRANTE*/
  @Input() datoFormEdad9; /*INPUT EDAD DE INTEGRRANTE*/
  @Input() datoFormEdad10; /*INPUT EDAD DE INTEGRRANTE*/

  @Input() isBitrix: boolean;
  @Input() isIdoc: boolean;
  @Input() isMobile: boolean;

  constructor(private http: HttpClient, private modalService: NgbModal) { }
  @Output() closeModal = new EventEmitter();
  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;
  modalMessage: string;
  textoPersonas: string;
  ngOnInit() {
    this.valor > 1
      ? (this.textoPersonas = "personas")
      : (this.textoPersonas = "persona");
    console.log(this.icono);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sendForm(
        this.idPrecio,
        this.price_1p,
        this.price_2p,
        this.price_3p,
        this.price_4p,
        this.price_p_adicional,
        this.price_copago,
        this.precio_adicional_65a69,
        this.precio_adicional_70a74,
        this.precio_adicional_75a79,
        this.precio_adicional_80mas
      );
    }, 1000);
  }
  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "contentModal",
      backdrop: "static",
      keyboard: false,
    });
  }

  contratar() {
    this.modalMessage = "Sus datos ya fueron enviados. En breve un representante comercial se comunicará con Ud."
    this.openModal(this.modalRespuesta)
    //window.open('https://www.vittal.com.ar/ecommerce/' + this.slug_plan, '_blank')
  }


  sendForm(
    idPrecio,
    price_1p,
    price_2p,
    price_3p,
    price_4p,
    price_p_adicional,
    price_copago,
    precio_adicional_65a69,
    precio_adicional_70a74,
    precio_adicional_75a79,
    precio_adicional_80mas
  ) {
    /*SETEOS*/
    var menor = 0;
    var adult = 0;
    var adult64a69 = 0;
    var adult70a74 = 0;
    var adult75a79 = 0;
    var adult80mas = 0;

    /*PRECIOS*/
    price_1p = parseFloat(price_1p);
    price_2p = parseFloat(price_2p);
    price_3p = parseFloat(price_3p);
    price_4p = parseFloat(price_4p);
    price_p_adicional = parseFloat(price_p_adicional);
    price_copago = parseFloat(price_copago);
    precio_adicional_65a69 = parseFloat(precio_adicional_65a69);
    precio_adicional_70a74 = parseFloat(precio_adicional_70a74);
    precio_adicional_75a79 = parseFloat(precio_adicional_75a79);
    precio_adicional_80mas = parseFloat(precio_adicional_80mas);

    /*EDADES*/
    var calcEdad1 = parseFloat(this.datoFormEdad1);
    var calcEdad2 = parseFloat(this.datoFormEdad2);
    var calcEdad3 = parseFloat(this.datoFormEdad3);
    var calcEdad4 = parseFloat(this.datoFormEdad4);
    var calcEdad5 = parseFloat(this.datoFormEdad5);
    var calcEdad6 = parseFloat(this.datoFormEdad6);
    var calcEdad7 = parseFloat(this.datoFormEdad7);
    var calcEdad8 = parseFloat(this.datoFormEdad8);
    var calcEdad9 = parseFloat(this.datoFormEdad9);
    var calcEdad10 = parseFloat(this.datoFormEdad10);

    /* CALCULA VALORES A MOTRAR EN PLACA DE RESULTADOS */
    if (this.valor) {
      for (var i = 0; i < this.valor; i++) {
        var val_item = i + 1;
        var people_value = 0;
        if (val_item == 1) {
          people_value = calcEdad1;
        }
        if (val_item == 2) {
          people_value = calcEdad2;
        }
        if (val_item == 3) {
          people_value = calcEdad3;
        }
        if (val_item == 4) {
          people_value = calcEdad4;
        }
        if (val_item == 5) {
          people_value = calcEdad5;
        }
        if (val_item == 6) {
          people_value = calcEdad6;
        }
        if (val_item == 7) {
          people_value = calcEdad7;
        }
        if (val_item == 8) {
          people_value = calcEdad8;
        }
        if (val_item == 9) {
          people_value = calcEdad9;
        }
        if (val_item == 10) {
          people_value = calcEdad10;
        }
        var edad_avanzada = false;

        if (people_value > 11) {
          if (people_value > 64 && people_value < 70) {
            adult64a69++;
            edad_avanzada = true;
          }

          if (people_value > 69 && people_value < 75) {
            adult70a74++;
            edad_avanzada = true;
          }

          if (people_value > 74 && people_value < 80) {
            adult75a79++;
            edad_avanzada = true;
          }

          if (people_value > 79) {
            adult80mas++;
            edad_avanzada = true;
          }

          if (edad_avanzada == false) {
            adult++;
          }
        } else {
          menor++;
        }
      }

      //Suma todos los adultos
      adult = adult + adult64a69 + adult70a74 + adult75a79 + adult80mas + menor;
      if (adult == 1) {
        var price = price_1p;
      }
      if (adult == 2) {
        var price = price_2p;
      }
      if (adult == 3) {
        var price = price_3p;
      }
      if (adult == 4) {
        var price = price_4p;
      }
      if (adult == 5) {
        var price = price_4p + price_p_adicional;
      }
      if (adult == 6) {
        var price = price_4p + price_p_adicional * 2;
      }
      if (adult == 7) {
        var price = price_4p + price_p_adicional * 3;
      }
      if (adult == 8) {
        var price = price_4p + price_p_adicional * 4;
      }
      if (adult == 9) {
        var price = price_4p + price_p_adicional * 5;
      }
      if (adult == 10) {
        var price = price_4p + price_p_adicional * 6;
      }
      /*ADICIONALES MAYORES*/
      if (adult64a69 > 0) {
        var price = price + precio_adicional_65a69 * adult64a69;
      }
      if (adult70a74 > 0) {
        var price = price + precio_adicional_70a74 * adult70a74;
      }
      if (adult75a79 > 0) {
        var price = price + precio_adicional_75a79 * adult75a79;
      }
      if (adult80mas > 0) {
        var price = price + precio_adicional_80mas * adult80mas;
      }

      /*MOSTRAR RESULTADOS EN PLACA*/
      var result = "res" + String(idPrecio);
      document.getElementById(result).innerHTML = "$ " + price;
      // console.log(
      //   "Menores: " + menor + "; Personas: " + adult + "; Precio: " + price
      // );

      if (this.primer) {
        var formData: any = new FormData();
        formData.append("nombre", this.nombrePersona);
        formData.append("apellido", this.apellidoPersona);
        formData.append("email", this.emailPersona);
        formData.append("telefono", this.telefonoPersona);
        formData.append("localidad", this.localidadPersona);
        formData.append("edad1", this.datoFormEdad1);
        formData.append("edad2", this.datoFormEdad2);
        formData.append("edad3", this.datoFormEdad3);
        formData.append("edad4", this.datoFormEdad4);
        formData.append("edad5", this.datoFormEdad5);
        formData.append("edad6", this.datoFormEdad6);
        formData.append("edad7", this.datoFormEdad7);
        formData.append("edad8", this.datoFormEdad8);
        formData.append("edad9", this.datoFormEdad9);
        formData.append("edad10", this.datoFormEdad10);
        formData.append("personas", adult);
        formData.append("menores", menor);
        formData.append("precio", price);
        formData.append("plan", this.nombre);
        console.log(this.isBitrix);
        if (this.isBitrix) {
          this.http
            .post<any>(
              `https://vittal.bitrix24.es/rest/581/frwu3wzs833exxye/crm.deal.add?FIELDS[UF_CRM_1634135217095]=${this.nombrePersona
              } ${this.apellidoPersona
              }&FIELDS[CATEGORY_ID]=3&FIELDS[STAGE_ID]=C3:NEW&FIELDS[UF_CRM_1631106488]=${location.href
              }&FIELDS[STATUS_ID]=NEW&FIELDS[UF_CRM_1634135302317]=${this.telefonoPersona
              }&FIELDS[UF_CRM_1634135324735]=${this.emailPersona
              }&FIELDS[UF_CRM_1629725987]=${this.valor.toString()}&FIELDS[ASSIGNED_BY_ID]=29`,
              ""
            )
            .subscribe(res => console.log(res)
            );
          this.http
            .post(
              API_BASE_URL_JSON +
              "/contact-form-7/v1/contact-forms/" +
              this.formCode +
              "/feedback",
              formData
            )
            .subscribe(
              (response) => console.log(response),
              (error) => console.log(error)
            );
        } else {
          this.http
            .post(
              API_BASE_URL_JSON +
              "/contact-form-7/v1/contact-forms/" +
              this.formCode +
              "/feedback",
              formData
            )
            .subscribe(
              (response) => console.log(response),
              (error) => console.log(error)
            );
        }
      }
    }
  }
}
