import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DataWpServiceLandings } from '../../data-wp.service';
import { PostI } from '../../post.interface';



@Component({
  selector: "app-landing-single",
  templateUrl: "./landing-single.component.html",
  styleUrls: ["./landing-single.component.css"],
  providers: [DataWpServiceLandings],
  encapsulation: ViewEncapsulation.None,
})
export class LandingSingleComponent implements OnInit {
  post: PostI;
  isBitrix: boolean;
  mobile: boolean;
  constructor(
    private postsService: DataWpServiceLandings,
    private route: ActivatedRoute
  ) {}

  getPost(slug) {
    this.postsService.getPost(slug).subscribe((res) => {
      console.log(res);
      this.post = res[0];
      this.post.acf["activarbitrix"]
        ? (this.isBitrix = true)
        : (this.isBitrix = false);
    });
  }

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      let slug = params["slug"];
      this.getPost(slug);
    });

    window.innerWidth < 481 ? (this.mobile = true) : (this.mobile = false);
  }
}
