import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { API_BASE_URL_JSON } from "src/app/data-wp.service";
import { emailData } from "src/app/shared/emailData";
import { SocioData } from "src/app/shared/socio-data.interface";

@Component({
  selector: "app-cambio-de-forma-de-pago",
  templateUrl: "./cambio-de-forma-de-pago.component.html",
  styleUrls: ["../../socios-gestion-online.component.css"],
})
export class CambioDeFormaDePagoComponent implements OnInit {
  form: FormGroup;
  constructor(
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    private http: HttpClient
  ) {}

  @Output() closeModal = new EventEmitter();
  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;

  datosSocio: SocioData;
  modalMessage: string;
  modalTitle: string;
  formaPago: string;

  /*FUNCION PARA SELECCIONAR PLAN*/

  selectHijos(value) {
    if (value == 1) {
      document.getElementById("formCredito").classList.add("show");
      document.getElementById("formDebito").classList.remove("show");
      document.getElementById("formPagoMisCuentas").classList.remove("show");
      document.getElementById("formCobrador").classList.remove("show");
      this.formaPago = "Tarjeta de crédito";
    }
    if (value == 2) {
      document.getElementById("formCredito").classList.remove("show");
      document.getElementById("formDebito").classList.add("show");
      document.getElementById("formPagoMisCuentas").classList.remove("show");
      document.getElementById("formCobrador").classList.remove("show");
      this.formaPago = "Débito en cuenta bancaria";
    }
    if (value == 3) {
      document.getElementById("formCredito").classList.remove("show");
      document.getElementById("formDebito").classList.remove("show");
      document.getElementById("formPagoMisCuentas").classList.add("show");
      document.getElementById("formCobrador").classList.remove("show");
      this.formaPago = "Pago Mis Cuentas";
    }
    if (value == 4) {
      document.getElementById("formCredito").classList.remove("show");
      document.getElementById("formDebito").classList.remove("show");
      document.getElementById("formPagoMisCuentas").classList.remove("show");
      document.getElementById("formCobrador").classList.add("show");
      this.formaPago = "Cobrador";
    }
  }

  /*FORMULARIOS FOMAS DE PAGO*/

  /*TARJETA DE CREDITO*/
  formCredito = this.formBuilder.group({
    credito: ["", [Validators.required]],
  });

  get credito() {
    return this.formCredito.get("credito");
  }

  /*DEBITO*/
  formDebito = this.formBuilder.group({
    debito: ["", [Validators.required, Validators.pattern("^[0-9]{22}$")]],
  });
  get debito() {
    return this.formDebito.get("debito");
  }

  /*PAGO MIS CUENTAS*/
  formPagoMisCuentas = this.formBuilder.group({
    pagomiscuentas: [true, [Validators.required]],
  });
  get pagomiscuentas() {
    return this.formPagoMisCuentas.get("pagomiscuentas");
  }

  /*COBRADOR*/
  formCobrador = this.formBuilder.group({
    direccion: ["", [Validators.required]],
  });
  get direccion() {
    return this.formCobrador.get("direccion");
  }

  spanTop() {
    document.getElementById("areaSpan").classList.add("top");
    document.getElementById("contentSelect").classList.add("ok");
  }

  spanTopCredito() {
    document.getElementById("areaSpanCred").classList.add("top");
    document.getElementById("contentSelectCred").classList.add("ok");
  }

  ngOnInit() {
    this.datosSocio = JSON.parse(sessionStorage.getItem("data"));
  }

  submitForm(tipoForm) {
    let formData = new FormData();
    let form = tipoForm.value;
    formData.append("numero_afiliado", this.datosSocio.socio_numero);
    formData.append("nombre_afiliado", this.datosSocio.socio_nombre);
    formData.append("apellido_afiliado", this.datosSocio.socio_apellido);
    formData.append("email_afiliado", this.datosSocio.socio_email);
    formData.append("dominio_afiliado", this.datosSocio.socio_dominio);
    formData.append("forma_pago", this.formaPago);

    if (this.formaPago == "Tarjeta de crédito") {
      formData.append("dato_pago", form.credito);
    }
    if (this.formaPago == "Débito en cuenta bancaria") {
      formData.append("dato_pago", form.debito);
    }
    if (this.formaPago == "Pago Mis Cuentas") {
      formData.append("dato_pago", form.pagomiscuentas);
    }
    if (this.formaPago == "Cobrador") {
      formData.append("dato_pago", form.direccion);
    }

    this.datosSocio.socio_dominio.length > 0
      ? formData.append(
          "email_destino",
          emailData[this.datosSocio.socio_dominio]
        )
      : formData.append("email_destino", "cas@vittal.com.ar");
    this.http
      .post<any>(
        API_BASE_URL_JSON + "/contact-form-7/v1/contact-forms/3826/feedback",
        formData
      )
      .subscribe((res) => {
        this.closeModal.emit();
        this.modalMessage = res.message;
        this.openModal(this.modalRespuesta);
      });
  }

  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }
}
