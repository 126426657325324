import { Component, OnInit, Input} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { phoneNumberValidator } from '../../../contacto/validators/phone-validator'
import { HttpClient } from '@angular/common/http';


import { API_BASE_URL_JSON } from '../../../data-wp.service';

@Component({
  selector: "app-form-contacto",
  templateUrl: "./form-contacto.component.html",
  styleUrls: ["./form-contacto.component.css"],
})
export class FormContactoComponent implements OnInit {
  @Input() codeForm: number;
  @Input() tituloForm: string; /*INPUT CON CODIGO DE FORMULARIO PARA ENVIAR*/
  @Input() isBitrix: boolean;
  form: FormGroup;

  constructor(public formBuilder: FormBuilder, private http: HttpClient) {}

  get nombre() {
    return this.contactForm.get("nombre");
  }
  get apellido() {
    return this.contactForm.get("apellido");
  }
  get email() {
    return this.contactForm.get("email");
  }
  get telefono() {
    return this.contactForm.get("telefono");
  }
  get localidad() {
    return this.contactForm.get("localidad");
  }

  contactForm = this.formBuilder.group({
    nombre: ["", [Validators.required]],
    apellido: ["", [Validators.required]],
    email: [
      "",
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"),
      ],
    ],
    telefono: ["", [Validators.required, phoneNumberValidator]],
    localidad: ["", [Validators.required]],
  });

  ngOnInit() {}

  sendForm() {
    document.getElementById("contentAll").classList.add("enviado");
    //console.log('se hizo el sumit');
  }

  submitForm(formCode) {
    if (!this.contactForm.valid) {
      alert("Revisar los campos");
    } else {
      var formData: any = new FormData();
      formData.append("nombre", this.contactForm.get("nombre").value);
      formData.append("apellido", this.contactForm.get("apellido").value);
      formData.append("email", this.contactForm.get("email").value);
      formData.append("telefono", this.contactForm.get("telefono").value);
      formData.append("localidad", this.contactForm.get("localidad").value);
      if (this.isBitrix) {
        this.http
          .post<any>(
            `https://vittal.bitrix24.es/rest/581/frwu3wzs833exxye/crm.deal.add?FIELDS[UF_CRM_1634135217095]=${
              this.contactForm.get("nombre").value
            } ${
              this.contactForm.get("apellido").value
            }&FIELDS[CATEGORY_ID]=3&FIELDS[STAGE_ID]=C3:NEW&FIELDS[UF_CRM_1631106488]=${
              location.href
            }&FIELDS[STATUS_ID]=NEW&FIELDS[UF_CRM_1634135302317]=${
              this.contactForm.get("telefono").value
            }&FIELDS[UF_CRM_1634135324735]=${
              this.contactForm.get("email").value
            }&FIELDS[ASSIGNED_BY_ID]=29`,
            ""
          )
          .subscribe();
      } else {
        this.http
          .post(
            API_BASE_URL_JSON +
              "/contact-form-7/v1/contact-forms/" +
              formCode +
              "/feedback",
            formData
          )
          .subscribe(
            (response) => console.log(response),
            (error) => console.log(error)
          );
      }
    }
  }

  otraConsulta() {
    location.reload();
    return false;
  }
}
